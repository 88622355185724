<template>
  <div>
    <el-dialog title="添加个性配置" :visible.sync="visible" :close-on-click-modal="false" width="400px">
      <el-input v-model="groupName" maxlength="20" placeholder="请输入个性配置名称" />
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="visible = false">取消</el-button>
        <el-button type="primary" @click.native="confirm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "AddPersonalConfigDia",
  props: {
    position: String,
    machineTypeId: [String, Number]
  },
  data() {
    return {
      visible: false,
      groupName: ''
    };
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    confirm() {
      if (!this.groupName) {
        this.$message.error('请填写配置名称')
        return
      }
      _api.addPersonalConfig({
        position: this.position,
        typeId: this.machineTypeId,
        groupName: this.groupName
      }).then((res) => {
        this.$message.success('添加成功')
        this.close()
        this.$emit('confirm')
      })
    }
  }
};
</script>