<template>
  <div>
    <el-dialog title="添加示例图" :visible.sync="visible" :close-on-click-modal="false" width="600px">
      <el-form ref="formRef" :model="formData" :rules="formRule" label-width="110px">
        <el-form-item label="示例图名称：" prop="imageName">
          <el-input v-model.trim="formData.imageName" maxlength="10" placeholder="请输入示例图名称" type="text" size="small" />
        </el-form-item>
        <el-form-item v-if="type == '01'" label="是否必填：" prop="isMust">
          <el-radio-group v-model="formData.isMust">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="示例图图片" prop="sampleImage">
           <!-- @handleChange="sampleHandleChange" -->
          <ElImgUpload
            :isBigImg="true"
            :exist-image="formData.sampleImage"
            placeTxt="提示：图片仅支持上传jpg/png/gif格式，建议尺寸：340-340px"
            @handleChange="sampleHandleChange"/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="visible = false">取消</el-button>
        <el-button type="primary" @click.native="confirm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import ElImgUpload from "@/components/global/components/elements/upload-element";

const originFormData = {
  imageName: '',
  isMust: '',
  sampleImage: '',
}
export default {
  name: "AddSampleImageDia",
  components: { ElImgUpload },
  props: {
    position: [String, Number],
    machineTypeId: [String, Number],
    groupId: [String, Number],
  },
  data() {
    return {
      visible: false,

      type: '', // 00示例图 01补充细节图
      formData: originFormData,
      formRule: {
        imageName: [{ required: true, message: '请输入示例图名称', trigger: 'change' }],
        isMust: [{ required: true, message: '请选择是否必填', trigger: 'change' }],
        sampleImage: [{ required: true, message: '请上传图片', trigger: 'change' }],
      }
    };
  },
  methods: {
    open(type, row) {
      this.type = type
      if (row) {
        this.formData = {
          id: row.id,
          imageName: row.imageName,
          isMust: row.isMust,
          sampleImage: row.sampleImage,
        }
        console.log(row)
      } else {
        this.formData = JSON.parse(JSON.stringify(originFormData))
      }
      this.$refs['formRef']?.clearValidate()
      this.$nextTick(() => {
        this.visible = true
      })
    },
    close() {
      this.visible = false
    },
    sampleHandleChange(v) {
      this.formData.sampleImage = v
    },
    confirm() {
      this.$refs['formRef'].validate((vaild) => {
        if (vaild) {
          let request = _api.ImageAdd
          if (this.type == '01') request = _api.ImageAddSpecial
          request({
            ...this.formData,
            position: this.position,
            machineTypeId: this.machineTypeId,
            groupId: this.groupId,
          }).then(() => {
            this.$emit('confirm', this.type)
            this.$message.success('操作成功！')
            this.close()
          })
        }
      })
    }
  }
};
</script>